import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// init firebase
initializeApp({
  apiKey: "AIzaSyDjFqM29BovYqypZv9RqmjudOl6_t6CUJQ",
  authDomain: "holipay-dev.firebaseapp.com",
  projectId: "holipay-dev",
  storageBucket: "holipay-dev.appspot.com",
  messagingSenderId: "596285702457",
  appId: "1:596285702457:web:8f3de7274ea2e7dfe6e8e8",
  measurementId: "G-GJS90PBCF4"
});
// inti services
const db = getFirestore();
const auth = getAuth();

export { db, auth }